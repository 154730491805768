@font-face {
  font-family: 'notpink';
  src: url('regular.ttf');
}

body {
  padding: 0;
  margin: 0;
  font-family: 'notpink';
  font-weight: 900;
  font-variant-numeric: tabular-nums;
}

p::selection {
  background: #ff00b3;
  color: #fff;
}